import React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import Box from '@mui/material/Box';

export default function CustomTable({ data, columns, tableActions }) {
  return (
    <div>
      <Box
        sx={{
          height: window.innerHeight < 700 ? 400 : window.innerHeight < 900 ? 500 : 650,
          minWidth: 300, 
          maxWidth: 1050,
          '& .active': {
            backgroundColor: 'rgba(157, 255, 118, 0.49)',
            fontWeight: 'bold',
          },
          '& .not_active': {
            backgroundColor: '#d47483',
            fontWeight: 'bold',
            color: 'white'
          },
        }}
      >  
        {data ? (
          <DataGrid 
            className='black-scroll-bar-container'
            sx={{
              backgroundColor: 'white',
              '& .MuiDataGrid-iconButtonContainer': {
                visibility: "visible",
                width: "0 !important",
                position: "relative",
                right: "1px !important",
                outline: 'none !important',
                pointerEvents: "none !important"
              },
              "& .MuiDataGrid-row:first-of-type": {
                marginTop: '8px'
              },
              "& .MuiDataGrid-columnHeaders": {
                zIndex: 1,
                borderBottom: '8px solid #F0F0F3',
                outline: 'none !important',
              },
              "& .MuiDataGrid-columnFooters": {
                borderBottom: '8px solid red',
                outline: 'none !important',
              },
              "&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus": {
                outline: 'none !important'
              },
              '.MuiDataGrid-columnSeparator': {
                display: 'none !important',
              },
              '&.MuiDataGrid-root': {
                borderRadius: '8px',
                border: '1px solid  #F0F0F3'
              },
              '&.MuiDataGrid-root .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus': {
                outline: 'none !important',
              },
            }}
            // sortModel={sortModel}
            // onSortModelChange={onSortModelChange}
            rows={data}
            columns={columns}
            rowsPerPageOptions={[100]}
            disableColumnMenu 
            onCellClick={tableActions}
            disableSelectionOnClick
            experimentalFeatures={{ newEditingApi: true }}
          />
        ) : null } 
      </Box>
    </div>
  );
}
