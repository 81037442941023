import React, { useEffect, useState } from "react";
import { serverHost } from "../../redux/reducers/reducer";
import SitesTable from "../../components/Dashboard/sites/sitesTable";
import axios from "axios";
import { Button, TextField, Dialog, DialogActions, DialogContent, DialogTitle, InputLabel, DialogContentText, MenuItem, Select, FormControl } from "@mui/material";
import Swal from 'sweetalert2';
import useAuth from '../../hooks/Auth/useAuth';
import "../../styles/tabelsPageStyles.css";
import PageHeader from "../../components/pageHeader/pageHeader";
import GenralConfirmOrCancelButton from "../../components/buttons/generalConfirmOrCancelButton";

const validateName = (input) => /^[A-Za-z0-9\s]+$/.test(input) || /[\u0590-\u05FF]/.test(input);

const Sites = () => {
    const serverhost = serverHost();
    const [sitesData, setSitesData] = useState([]);
    const { sitesList, setSitesList } = useAuth();
    const [newSite, setNewSite] = useState('');
    const [checkSiteName, setCheckSiteName] = useState(true);
    const [addError, setAddError] = useState(false);
    const [msgError, setMsgError] = useState("");
    const [editOpen, setEditOpen] = useState(false);
    const [editName, setEditName] = useState("");
    const [editSite, setEditSite] = useState({ id: '', name: '' });
    const [open, setOpen] = useState(false);
    const [siteStatuses, setSiteStatuses] = useState({});
    const [tableData, setTableData] = useState([]);
    const [versionDialogOpen, setVersionDialogOpen] = useState(false);
    const [selectedVersion, setSelectedVersion] = useState("");
    const [currentSite, setCurrentSite] = useState({});

    const handleClickOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handleChange = (event, type) => {
        const input = event.target.value;
        const isValid = validateName(input);
        setCheckSiteName(isValid && input.length >= 2);

        if (type === 'add') {
            setNewSite(input);
        } else {
            setEditSite(prevState => ({
                ...prevState,
                ['name']: input
            }));
        }
    };

    const editFunction = (row) => {
        if (!row) return;

        const { id, site_name } = row;

        const choosenSite = { ...editSite };
        choosenSite.id = id;
        choosenSite.name = site_name;
        setEditName(site_name);
        setEditSite(choosenSite);
        setEditOpen(true);
    };

    const addSite = () => {
        setMsgError("");
        if (checkSiteName && newSite.length > 2) {
            setAddError(false);
            
            axios.post(serverhost + '/site/add', { name: newSite })
                .then(res => {
                    getAllSites();
                    handleClose();
                })
                .catch(function (error) {
                    if (Number(error.response.status) === 404) {
                        setMsgError("Site name must be unique!");
                    } else if (Number(error.response.status) === 500) {
                        setMsgError("Please try again...");
                    } else {
                        setMsgError("Please try again...");
                    }
                });
        } else {
            setAddError(true);
        }
    };

    const deleteSite = (row) => {
        if (!row) return;
        const { id, site_name } = row;

        setMsgError("");
        Swal.fire({
            title: 'Are you sure you want to delete this site?',
            html: `Site to delete : <b>${site_name}</b>.`,
            showCancelButton: true,
            confirmButtonText: 'Yes delete!',
        }).then((result) => {
            if (result.isConfirmed) {
                axios.post(serverhost + '/site/delete', { name: site_name, id: id })
                    .then(res => {
                        getAllSites();
                    })
                    .catch(err => {
                        console.log(err);
                    });
            }
        });
    };

    const updateSite = () => {
        if (checkSiteName && editSite.name.length > 2) {
            setAddError(false);
            axios.post(serverhost + '/site/update', { name: editSite.name, id: editSite.id })
                .then(res => {
                    getAllSites();
                    setEditOpen(false);
                })
                .catch(function (error) {
                    if (Number(error.response.status) === 404) {
                        setMsgError("Site name must be unique!");
                    } else if (Number(error.response.status) === 500) {
                        setMsgError("Please try again...");
                    } else {
                        setMsgError("Please try again...");
                    }
                });
        } else {
            setAddError(true);
        }
    };

    const getAllSites = () => {
        axios.get(serverhost + '/site/all')
            .then(res => {
                if (res.status === 200) {
                    const sitesDataResponse = res.data;
                    const sites = [];
                    for (const element of sitesDataResponse) {
                        sites.push(element.site_name);
                    }
                    if (window.location.href.includes('localhost')) {
                        const fakeSite = { id: "1111-11111--111111-111111", site_name: "localhost" };
                        sitesDataResponse.unshift(fakeSite);
                        sites.push('localhost');
                    }

                    setSitesData(sitesDataResponse);
                    setSitesList(sites);
                }
            });
    };

    useEffect(() => {
        getAllSites();
    }, []);

    const getSitesStatuses = (sites_list) => {
        const promisesArray = [];

        for (const site of sites_list) {
            let url;
            if (site !== 'localhost') {
                url = "https://" + site + '.carteav.com';
            } else {
                url = "http://localhost:8080";
            }

            const promise = new Promise((resolve) => {
                axios.get(`${url}/admin/siteStatus`)
                    .then(res => {
                        resolve({ site, status: res.data });
                    })
                    .catch(error => {
                        let status;
                        if (error.response) {
                            status = { error: `Server responded with status code: ${error.response.status}`, data: error.response.data };
                        } else if (error.request) {
                            status = { error: "No response received from the server" };
                        } else {
                            status = { error: `Error in setting up the request: ${error.message}` };
                        }
                        resolve({ site, status });
                    });
            });

            promisesArray.push(promise);
        }

        Promise.allSettled(promisesArray)
            .then(results => {
                const statuses = results.reduce((acc, result) => {
                    if (result.status === 'fulfilled') {
                        acc[result.value.site] = result.value.status;
                    } else {
                        acc[result.reason.site] = { error: result.reason.message };
                    }
                    return acc;
                }, {});
                setSiteStatuses(statuses);
            })
            .catch(error => {
                console.log("Error fetching(allSettled) site statuses:", error);
            });
    };

    useEffect(() => {
        getSitesStatuses(sitesList);

        const intervalId = setInterval(() => {
            getSitesStatuses(sitesList);
        }, 60000);

        return () => clearInterval(intervalId);
    }, [sitesList]);

    useEffect(() => {
        const combinedData = sitesData.map(site => ({
            ...site,
            ...siteStatuses[site.site_name],
            edit: editFunction,
            delete: deleteSite,
            changeDBVersion: (p_site) => handleVersionChangeOpen(p_site)
        }));
        setTableData(combinedData);
    }, [sitesData, siteStatuses]);

    const handleVersionChangeOpen = (site) => {
        setCurrentSite(site);
        setSelectedVersion("");
        setVersionDialogOpen(true);
    };

    const handleVersionChangeClose = () => setVersionDialogOpen(false);

    const handleVersionChange = () => {
        if (selectedVersion) {
            Swal.fire({
                title: 'Are you sure?',
                text: `You are about to change the database version of ${currentSite.site_name} to ${selectedVersion}. This action is potentially dangerous.`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes, change it!',
                cancelButtonText: 'No, keep it',
                customClass: {
                    popup: 'sweetalert2-dialog'
                }
            }).then((result) => {
                if (result.isConfirmed) {
                    let url;
                    const site =currentSite?.site_name; 
                    console.log(site);
                    if (site !== 'localhost') {
                        url = "https://" + site + '.carteav.com';
                    } else {
                        url = "http://localhost:8080";
                    }

                    axios.post(`${url}/admin/replaceDatabaseFile`, { id: currentSite.id, version: selectedVersion })
                        .then(res => {
                            getAllSites();
                            setVersionDialogOpen(false);
                            Swal.fire({
                                title: 'Changed!',
                                text: 'The database version has been changed.',
                                icon: 'success',
                                customClass: {
                                    popup: 'sweetalert2-dialog'
                                }
                            });
                        })
                        .catch(error => {
                            console.error("Error changing database version:", error);
                            Swal.fire({
                                title: 'Error!',
                                text: 'There was an error changing the database version. Please try again.',
                                icon: 'error',
                                customClass: {
                                    popup: 'sweetalert2-dialog'
                                }
                            });
                        });
                }
            });
        }
    };
    return (
        <>
            <div className="bodyContainer">
                <PageHeader
                    text={"Sites"}
                    buttonsContainer={
                        <>
                            <GenralConfirmOrCancelButton
                                type={'confirm'}
                                onClick={handleClickOpen}
                                text={'Add site'}
                                id={'addSiteButton'}
                            />
                        </>
                    }
                />
                <SitesTable data={tableData} />
            </div>

            <Dialog open={open} onClose={handleClose}>
                <DialogTitle className="display_center">ADD SITE</DialogTitle>
                <DialogContent sx={{ minWidth: 350 }}>
                    <TextField
                        sx={{ "& fieldset": { border: 'none' } }}
                        autoFocus
                        margin="dense"
                        id="Site_Name"
                        label="Site Name"
                        type="text"
                        fullWidth
                        variant="standard"
                        onChange={(e) => handleChange(e, 'add')}
                        inputProps={{ maxLength: 20 }}
                        error={addError}
                    />
                    <InputLabel error htmlFor="name-error">
                        <b style={{ opacity: '0.9', fontSize: '0.8rem', position: 'relative', left: 6 }}>
                            {checkSiteName ? null : 'Site Name must be only and at least 2 characters!'}
                        </b>
                    </InputLabel>
                    <InputLabel error htmlFor="server-error">
                        <b style={{ opacity: '0.9', fontSize: '0.8rem', position: 'relative', left: 6 }}>
                            {msgError}
                        </b>
                    </InputLabel>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={addSite}>ADD</Button>
                </DialogActions>
            </Dialog>

            <Dialog open={editOpen} onClose={() => setEditOpen(false)}>
                <DialogTitle className="display_center">EDIT SITE {editName}</DialogTitle>
                <DialogContent sx={{ minWidth: 350 }}>
                    <DialogContentText>ID: {editSite.id}</DialogContentText>
                    <TextField
                        sx={{ "& fieldset": { border: 'none' } }}
                        autoFocus
                        margin="dense"
                        id="Site_Name"
                        label="New Site Name"
                        type="text"
                        fullWidth
                        variant="standard"
                        onChange={(e) => handleChange(e, 'edit')}
                        inputProps={{ maxLength: 20 }}
                        value={editSite.name}
                        error={addError}
                    />
                    <InputLabel error htmlFor="name-error">
                        <b style={{ opacity: '0.9', fontSize: '0.8rem', position: 'relative', left: 6 }}>
                            {checkSiteName ? null : 'Site Name must be only and at least 2 characters!'}
                        </b>
                    </InputLabel>
                    <InputLabel error htmlFor="server-error">
                        <b style={{ opacity: '0.9', fontSize: '0.8rem', position: 'relative', left: 6 }}>
                            {msgError}
                        </b>
                    </InputLabel>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setEditOpen(false)}>Cancel</Button>
                    <Button onClick={updateSite}>SAVE</Button>
                </DialogActions>
            </Dialog>

            <Dialog open={versionDialogOpen} onClose={handleVersionChangeClose} sx={{zIndex:'1000'}}>
                <DialogTitle className="display_center">Change Database Version for site {currentSite?.site_name}</DialogTitle>
                <DialogContent sx={{ minWidth: 350,minHeight:'80px',display:'flex',alignItems:'center'}}>
                    <FormControl fullWidth>
                        <InputLabel id="version-select-label">Version</InputLabel>
                        <Select
                            labelId="version-select-label"
                            id="version-select"
                            value={selectedVersion}
                            label="Version"
                            onChange={(e) => setSelectedVersion(e.target.value)}
                        >   
                            {Array.isArray(currentSite?.db_backup_files) && currentSite.db_backup_files.map((version) => (
                                <MenuItem key={version} value={version}>
                                    {version}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleVersionChangeClose}>Cancel</Button>
                    <Button onClick={handleVersionChange} disabled={!selectedVersion}>Change</Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default Sites;
